<template>
  <div>
    <van-loading size="72px">加载中...</van-loading>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      appid2: process.env.VUE_APP_APPID
    };
  },
  computed: {},
  created: function() {
    console.log(process.env);
  let code = this.$route.query.code;
    // let redirect= this.$route.query.redirect
    //console.log( this.$route.query)
    if (code == undefined||code=="") {
      this.login();
    } else {
       var returnurl = this.getUrlParam("returnurl")
  console.log("returnurl="+returnurl)

      this.$api.login.wxlogin(code).then(res => {
        if(res.data.status==1){
        localStorage.setItem("token", res.data.data.token);
if(returnurl){
 this.$router.replace(returnurl);
 
}
else{
   this.$router.replace("/")
}

        }else{
          this.$toast.fail("微信授权失败")
        }
        
      });
    }
  },
  methods: {
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); // 匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; // 返回参数值
    },
    randomPassword(size) {
      var seed = new Array(
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "m",
        "n",
        "p",
        "Q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ); //数组
      var seedlength = seed.length; //数组长度
      var createPassword = "";
      for (var i = 0; i < size; i++) {
        var j = Math.floor(Math.random() * seedlength);
        createPassword += seed[j];
      }
      return createPassword;
    },
    // login() {
    //   window.location.href =
    //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx21c555cbe5d0e160&redirect_uri=http://appointment.allsparksoft.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=123456#wechat_redirect";
    // }
    login() {
      let domain = process.env.VUE_APP_DOMAIN;
      console.log(domain);
      let appid = process.env.VUE_APP_APPID;
      console.log(appid);
      var m = this.randomPassword(6);
      var returnurl = this.getUrlParam("return");
      var redirecturi = encodeURIComponent(
        domain + "/login?returnurl=" + returnurl
      );
      let l =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        redirecturi +
        "&response_type=code&scope=snsapi_userinfo&state=" +
        m +
        "#wechat_redirect";
      window.location.href = l;
    }
  }
};
</script>
<style lang="scss" scoped>
.van-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>